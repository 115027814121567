@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&family=Poppins:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}

/* ********************** */
/* Stick Horizontal       */
/* ********************** */
.h_line {
  background: lightslategrey;
  margin-top: -25px;
  margin-bottom: 4px;
  /* margin-right: -1px; */
  height: 0.25rem;
  width: 2rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.h_line:hover {
  background: black;
}

.h_line.clicked {
  background: red;
  margin-top: -25px;
  margin-bottom: 4px;
  height: 0.25rem;
  width: 2rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.h_line.clicked:hover {
  background: black;
}


.ht_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 2px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.ht_line:hover {
  background: black;
}

.ht_line.clicked {
  position: absolute;
  background: red;
  margin-top: 2px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.ht_line.clicked:hover {
  background: black;
}

.hm_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 46px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.hm_line:hover {
  background: black;
}

.hm_line.clicked {
  position: absolute;
  background: red;
  margin-top: 46px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.hm_line.clicked:hover {
  background: black;
}

.hb_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 70px;
  margin-left: 5px;
  /* margin-bottom: 4px; */
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.hb_line:hover {
  background: black;
}

.hb_line.clicked {
  position: absolute;
  background: red;
  margin-top: 70px;
  margin-left: 5px;
  /* margin-bottom: 4px; */
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.hb_line.clicked:hover {
  background: black;
}


/* ********************** */
/* Stick Vertical         */
/* ********************** */
.v_line {
  background: lightslategrey;
  height: 2rem;
  width: 0.2rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.v_line:hover {
  background: black;
}

.v_line.clicked {
  background: red;
  height: 2rem;
  width: 0.2rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.v_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Stick Vertical         */
/* ********************** */
.vl_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 6px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vl_line:hover {
  background: black;
}

.vl_line.clicked {
  position: absolute;
  background: red;
  margin-top: 6px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vl_line.clicked:hover {
  background: black;
}

.vm_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 50px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vm_line:hover {
  background: black;
}

.vm_line.clicked {
  position: absolute;
  background: red;
  margin-top: 50px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vm_line.clicked:hover {
  background: black;
}

.vr_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 6px;
  margin-left: 45px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vr_line:hover {
  background: black;
}

.vr_line.clicked {
  position: absolute;
  background: red;
  margin-top: 6px;
  margin-left: 45px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vr_line.clicked:hover {
  background: black;
}

.vn_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 50px;
  margin-left: 45px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vn_line:hover {
  background: black;
}

.vn_line.clicked {
  position: absolute;
  background: red;
  margin-top: 50px;
  margin-left: 45px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.vn_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Stick Oper Vertical    */
/* ********************** */
.p_line {
  background: lightslategrey;
  margin-top: 27px;
  margin-left: 15px;
  height: 2.5rem;
  width: 0.25rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.p_line:hover {
  background: black;
}

.p_line.clicked {
  background: red;
  margin-top: 27px;
  margin-left: 15px;
  height: 2.5rem;
  width: 0.25rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.p_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!!!!!! */

.op_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 27px;
  margin-left: 23px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.op_line:hover {
  background: black;
}

.op_line.clicked {
  position: absolute;
  background: red;
  margin-top: 27px;
  margin-left: 23px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.op_line.clicked:hover {
  background: black;
}

.opl_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 27px;
  margin-left: 8px;
  height: 4rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.opl_line:hover {
  background: black;
}

.opl_line.clicked {
  position: absolute;
  background: red;
  margin-top: 27px;
  margin-left: 8px;
  height: 4rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.opl_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Stick oper Horizontal  */
/* ********************** */
.q_line {
  background: lightslategrey;
  margin-top: 28px;
  margin-left: -19px;
  height: 0.25rem;
  width: 2.5rem;
  display: flex;
  opacity: 0.2;
  color: white;
  font-size: 1rem;
}

.q_line:hover {
  background: black;
}

.q_line.clicked {
  background: red;
  margin-top: 28px;
  margin-left: -19px;
  height: 0.25rem;
  width: 2.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.q_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!!!! */

.oq_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 44px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oq_line:hover {
  background: black;
}

.oq_line.clicked {
  position: absolute;
  background: red;
  margin-top: 44px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oq_line.clicked:hover {
  background: black;
}

.oql_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 44px;
  margin-left: -10px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oql_line:hover {
  background: black;
}

.oql_line.clicked {
  position: absolute;
  background: red;
  margin-top: 44px;
  margin-left: -10px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oql_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Stick Oper Back Slash  */
/* ********************** */
.r_line {
  background: lightslategrey;
  margin-top: 28px;
  margin-left: 1px;
  height: 3rem;
  width: 0.2rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.r_line:hover {
  background: black;
}

.r_line.clicked {
  background: red;
  margin-top: 28px;
  margin-left: 1px;
  height: 3rem;
  width: 0.2rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.r_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!!! */

.or_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 14px;
  margin-left: 22px;
  height: 7rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.or_line:hover {
  background: black;
}

.or_line.clicked {
  position: absolute;
  background: red;
  margin-top: 14px;
  margin-left: 22px;
  height: 7rem;
  width: 0.4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.or_line.clicked:hover {
  background: black;
}

.orl_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 20px;
  margin-left: 45px;
  height: 5rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.orl_line:hover {
  background: black;
}

.orl_line.clicked {
  position: absolute;
  background: red;
  margin-top: 20px;
  margin-left: 45px;
  height: 5rem;
  width: 0.4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.orl_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Stick Oper Slash       */
/* ********************** */
.l_line {
  background: lightslategrey;
  margin-top: 28px;
  margin-left: -6px;
  height: 3rem;
  width: 0.2rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.l_line:hover {
  background: black;
}

.l_line.clicked {
  background: red;
  margin-top: 28px;
  margin-left: -6px;
  height: 3rem;
  width: 0.2rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.l_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!!!! */

.ol_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 14px;
  margin-left: 23px;
  height: 7rem;
  width: 0.4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ol_line:hover {
  background: black;
}

.ol_line.clicked {
  position: absolute;
  background: red;
  margin-top: 14px;
  margin-left: 23px;
  height: 7rem;
  width: 0.4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ol_line.clicked:hover {
  background: black;
}

.oll_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 20px;
  margin-left: 45px;
  height: 5rem;
  width: 0.5rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.oll_line:hover {
  background: black;
}

.oll_line.clicked {
  position: absolute;
  background: red;
  margin-top: 20px;
  margin-left: 45px;
  height: 5rem;
  width: 0.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

.oll_line.clicked:hover {
  background: black;
}

/* ********************** */
/* stick Oper down        */
/* ********************** */
.e_line {
  background: lightslategrey;
  margin-top: 57px;
  margin-left: -37px;
  height: 0.2rem;
  width: 2.5rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.e_line:hover {
  background: black;
}

.e_line.clicked {
  background: red;
  margin-top: 57px;
  margin-left: -37px;
  height: 0.2rem;
  width: 2.5rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.e_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!!!!!!! */

.oe_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 70px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oe_line:hover {
  background: black;
}

.oe_line.clicked {
  position: absolute;
  background: red;
  margin-top: 70px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oe_line.clicked:hover {
  background: black;
}

.oel_line {
  position: absolute;
  background: lightslategrey;
  margin-top: 70px;
  margin-left: -10px;
  height: 0.5rem;
  width: 4rem;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oel_line:hover {
  background: black;
}

.oel_line.clicked {
  position: absolute;
  background: red;
  margin-top: 70px;
  margin-left: -10px;
  height: 0.5rem;
  width: 4rem;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.oel_line.clicked:hover {
  background: black;
}

/* ********************** */
/* stick desativo         */
/* ********************** */
.s_line {
  height: 2rem;
  width: 0.2rem;
  display: flex;
  color: white;
  font-size: 1rem;
}

/* ********************** */
/* Igual / stick superior */
/* ********************** */
.x_line {
  background: red;
  margin-top: 24px;
  height: 0.25rem;
  width: 2rem;
  display: flex;
  opacity: 0.2;
  /* cursor: pointer; */
  color: white;
  font-size: 1rem;
  cursor: not-allowed;
}

.x_line:hover {
  background: black;
}

.x_line.clicked {
  background: red;
  margin-top: 24px;
  height: 0.25rem;
  width: 2rem;
  display: flex;
  opacity: 1;
  /* cursor: pointer; */
  color: white;
  font-size: 1rem;
  cursor: not-allowed;
}

.x_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!! */

.eu_line {
  position: absolute;
  background: red;
  margin-top: 12px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  display: flex;
  opacity: 0.2;
  /* cursor: pointer; */
  color: white;
  font-size: 1rem;
  cursor: not-allowed;
}

.eu_line:hover {
  background: black;
}

.eu_line.clicked {
  position: absolute;
  background: red;
  margin-top: 12px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  display: flex;
  opacity: 1;
  /* cursor: pointer; */
  color: white;
  font-size: 1rem;
  cursor: not-allowed;
}

.eu_line.clicked:hover {
  background: black;
}

/* ********************** */
/* Igual / stick inferior */
/* ********************** */
.y_line {
  background: lightslategrey;
  margin-top: 0px;
  height: 0.2rem;
  width: 2rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.y_line:hover {
  background: black;
}

.y_line.clicked {
  background: red;
  margin-top: -5px;
  height: 0.25rem;
  width: 2rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.y_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!! */

.ed_line {
  position: absolute;
  background: lightslategrey;
  margin-top: -5px;
  margin-left: 5px;
  height: 0.4rem;
  width: 4rem;
  display: flex;
  opacity: 0.2;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.ed_line:hover {
  background: black;
}

.ed_line.clicked {
  position: absolute;
  background: red;
  margin-top: -5px;
  margin-left: 5px;
  height: 0.5rem;
  width: 4rem;
  display: flex;
  opacity: 1;
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

.ed_line.clicked:hover {
  background: black;
}

/* !!!!!!!!!!!!!!!! */

.App {
  height: 80vh;
  width: 95%;
  max-width: 500px;
  /* background: rgb(11, 30, 52); */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.board {
  position: relative;
  margin-top: 2%;
  margin-left: 5%;
  margin-bottom: auto;
  /* padding: auto; */
  display: grid;
  grid-template-columns: repeat(8, auto);
  /* align-items: center; */
  /* justify-content: center; */
  grid-gap: 80px;
}

.boardL {
  position: relative;
  margin-top: 2%;
  margin-left: 5%;
  margin-bottom: auto;
  /* padding: auto; */
  display: grid;
  grid-template-columns: repeat(8, auto);
  /* align-items: center; */
  /* justify-content: center; */
  grid-gap: 80px;
}

.frame {
  margin-top: 2%;
  margin-left: 5%;
  display: flex;
  /* grid-template-columns: repeat(7, auto); */
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
}

.display {
  /* margin-top: 20px; */
  margin-left: 20px;
  padding-left: 5px;
  padding-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
}

.display_op {
  margin-top: -30px;
  margin-left: 20px;
  margin-right: -7px;
  padding-left: 5px;
  /* padding-right: 5px; */
  display: flex;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
}

.btn {
  background: rgb(0, 0, 0);
  color: rgb(208, 213, 255);
  border: 2px solid rgb(214, 235, 255);
  font-size: 1.25rem;
  /* margin-top: -1.5rem; */
  margin-left: 1%;
  padding: 5px 6px;
  cursor: pointer;
  /* border-radius: 5px; */
  transition: 0.3s ease-out;
  max-height: 5rem;
}

.btn:hover {
  background: rgb(63, 118, 170);
  color: rgb(27, 255, 255);
  border: 2px solid rgb(27, 255, 255);
}

.title {
  color: black;
  font-size: 2rem;
  /* margin-left: 10%; */
  text-align: center;
}

.sub-title {
  color: black;
  font-size: 1.5rem;
  /* margin-left: 10%; */
  text-align: center;
}

.modal_msg {
	margin: 0px 0;
	font-size: 18px;
	color: black;
	text-align: left;
}

.modal_info {
	margin: 0px 0;
  font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: black;
	text-align: left;
}

.modal_list_view {
	margin: 0px 0;
	font-size: 18px;
	color: black;
	text-align: left;
}

@media (min-width: 768px) {
  /* .title {
    color: black;
    font-size: 2rem;
    margin-left: 5%;
    text-align: center;
  }

  .sub-title {
    color: black;
    font-size: 1.8rem;
    margin-left: 1%;
    text-align: center;
  } */

  .frame {
    /* margin-top: 2%; */
    margin-left: 5%;
    /* display: flex;
    grid-template-columns: repeat(7, auto);
    align-items: center;
    justify-content: center;
    grid-gap: 5px; */
  }

  /* .btn {
    margin-left: 2%;
  } */
  .modal_info {
    margin: 0px 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: black;
    text-align: left;
  }

}


